<ng-template #formNormal>
    <ng-container [formGroup]="dataForm">
        @if (env.type == 'oriente-petrolero') {
            <div class="col-span-full input_group no_space py-2 sm:!px-4 mt-4 flex flex-wrap items-center bg-[#ebebeb] !w-fit rounded">
                <label for="type-wilster">Oriente Petrolero</label>
                <div class="switch__field switch__option mx-4">
                    <input type="checkbox" id="type-oriente" name="type-oriente" [(ngModel)]="type_oriente"
                        [ngModelOptions]="{standalone: true}" (change)="typeOriente(type_oriente)">
                    <span></span>
                </div>
                <label for="type-wilster">Adulto Mayor</label>
            </div>
            <div class="col-span-full flex flex-wrap justify-center items-center w-full py-4">
                <label for="image" class="w-[155px] relative before:block before:pb-[100%] bg-[#f3f3f3] rounded-md">
                    <img *ngIf="imagePreview" [src]="imagePreview" class="w-full h-full absolute top-[50%] left-[50%] -translate-y-1/2 -translate-x-1/2 object-cover object-center" alt="Profile Image">
                </label>
                <div class="py-2 sm:!px-4">
                    <label for="image">Foto de perfil*</label>
                    <div class="content__file-input cursor-pointer">
                        <label for="image" class="btn__image-change flex items-center py-2 px-4 rounded bg-[color:var(--primary-color)] hover:bg-[color:var(--second-color)] text-white !text-sm font-semibold cursor-pointer text-base">
                            <i class="fa-light fa-image mr-2"></i>
                            <span>Seleccionar Imagen</span>
                        </label>
                        <input type="file" name="image" id="image" accept="image/*" (change)="showPreview($event)" required class="file__img hidden">
                    </div>
                </div>
            </div>
        }
        <div class="input_group no_space py-2 sm:!px-4">
            <label for="first_name">Nombres*</label>
            <input id="first_name" type="name" name="first_name" formControlName="first_name" placeholder="Escriba aquí..." />
        </div>
        <div class="input_group no_space py-2 sm:!px-4">
            <label for="last_name">Apellidos*</label>
            <input id="last_name" type="name" name="last_name" formControlName="last_name" placeholder="Escriba aquí..." />
        </div>
        <div class="input_group no_space py-2 sm:!px-4">
            <label for="email">Correo electrónico*</label>
            <input id="email" type="email" name="email" formControlName="email" placeholder="Escriba aquí..." />
        </div>
        <div class="input_group no_space py-2 sm:!px-4">
            <label for="phone_number">Celular*</label>
            <input id="phone" type="phone_number" name="phone_number" formControlName="phone_number" placeholder="Escriba aquí..." />
        </div>
        <div class="input_group no_space py-2 sm:!px-4">
            <label for="birthdate">Fecha de nacimiento*</label>
            <input id="birthdate" type="date" name="birthdate" formControlName="birthdate" placeholder="Escriba aquí..." />
        </div>
        <div class="input_group no_space py-2 sm:!px-4">
            <label for="gender">Sexo*</label>
            <mat-select id="gender" name="gender" formControlName="gender" placeholder="Seleccione una opción" class="custom__mat-select">
                <div class="filter_option">
                    <input placeholder="Buscar" appSearch (appSearch)="filterGenders = $event" class="!outline-none !ring-0 !border-0">
                </div>
                <mat-option *ngFor="let item of genders | filter: filterGenders : 'name'" [value]="item.id" >{{ item.name }}</mat-option>
            </mat-select>
        </div>
        <div class="input_group no_space py-2 sm:!px-4">
            <label for="ci_number">Número de C.I.*</label>
            <input id="ci_number" type="number" name="ci_number" formControlName="ci_number" placeholder="Escriba aquí..." />
        </div>
        

        @if (env.type != 'club-colocolo') {
            <div class="input_group no_space py-2 sm:!px-4">
                <label for="ci_expedition_basic">Expedición de C.I.*</label>
                <mat-select id="ci_expedition_basic" name="ci_expedition_basic" formControlName="ci_expedition_basic" placeholder="Seleccione una opción" class="custom__mat-select">
                    <div class="filter_option">
                        <input placeholder="Buscar" appSearch (appSearch)="filterExpeditionCI = $event" class="!outline-none !ring-0 !border-0">
                    </div>
                    <mat-option *ngFor="let item of expeditionData | filter: filterExpeditionCI : 'name'" [value]="item.id" >{{ item.name }}</mat-option>
                </mat-select>
            </div>
        }
        <div class="input_group no_space py-2 sm:!px-4">
            <label for="ci_extension">Complemento - opcional</label>
            <input id="ci_extension" type="text" name="ci_extension" formControlName="ci_extension" placeholder="Escriba aquí..." />
        </div>
    
        @if (env.type != 'san-jose' && env.type != 'oriente-petrolero') {
            <div class="input_group no_space py-2 sm:!px-4">
                <label for="invoice_name">Razón social</label>
                <input id="invoice_name" type="name" name="invoice_name" formControlName="invoice_name" placeholder="Escriba aquí..." />
            </div>
            <div class="input_group no_space py-2 sm:!px-4">
                <label for="invoice_nit">NIT</label>
                <input id="invoice_nit" type="text" name="invoice_nit" formControlName="invoice_nit" placeholder="Escriba aquí..." />
            </div>    
        }

        @if (env.type === 'oriente-petrolero' && type_oriente === true ) {
            <div class="col-span-full flex gap-4 flex-wrap md:flex-nowrap ">
                <div class="input_group no_space py-2 sm:!px-4">
                    <label for="ci_image_front" class="w-full h-[145px] bg-[#f3f3f3] rounded cursor-pointer !flex justify-center items-center flex-col overflow-hidden hover:bg-[#d0d0d0]">
                        <img class="w-full object-contain" *ngIf="imagePreviewDoc1" [src]="imagePreviewDoc1" alt="doc">
                        Subir foto de carnet anverso
                    </label>
                    <input id="ci_image_front" type="file" name="ci_image_front" class="!hidden opacity-0" accept="image/*" (change)="showPreviewDoc1($event)" />
                </div>
                <div class="input_group no_space py-2 sm:!px-4">
                    <label for="ci_image_back"  class="w-full h-[145px] bg-[#f3f3f3] rounded cursor-pointer !flex justify-center items-center flex-col overflow-hidden hover:bg-[#d0d0d0]" >
                        <img class="w-full object-contain" *ngIf="imagePreviewDoc2" [src]="imagePreviewDoc2" alt="doc">
                        Subir foto de carnet anreverso
                    </label>
                    <input id="ci_image_back" type="file" name="ci_image_back" class="!hidden opacity-0" accept="image/*" (change)="showPreviewDoc2($event)" />
                </div>
            </div>

        }

        <div class="input_group no_space py-2 sm:!px-4">
            <label for="country_id">País*</label>
            <mat-select id="country_id" name="country_id" formControlName="country_id" placeholder="Seleccione una opción" class="custom__mat-select">
                <div class="filter_option">
                    <input placeholder="Buscar" appSearch (appSearch)="filterCountries = $event" class="!outline-none !ring-0 !border-0">
                </div>
                <ng-container *ngIf="!loadingCountries; else elseLoadingCountries">
                    <ng-container *ngIf="xServices.lengthItems(countries); else elseCountries">
                        <mat-option *ngFor="let item of countries | filter: filterCountries : 'name'" [value]="item['id']" >{{ item.name }} </mat-option>
                    </ng-container>
                    <ng-template #elseCountries>
                        <mat-option [value]="null">No se encontraron paises</mat-option>
                    </ng-template>
                </ng-container>
                <ng-template #elseLoadingCountries>
                    <mat-option [value]="null">Cargando paises...</mat-option>
                </ng-template>
            </mat-select>
        </div>
        <div class="input_group no_space py-2 sm:!px-4">
            <label for="city_id">Ciudad*</label>
            <mat-select id="city_id" name="city_id" formControlName="city_id" [placeholder]="dataForm.get('country_id')?.value ? 'Seleccione una opción' : 'Seleccione un País'" class="custom__mat-select">
                <div class="filter_option">
                    <input placeholder="Buscar" appSearch (appSearch)="filterCities = $event" class="!outline-none !ring-0 !border-0">
                </div>
                <ng-container *ngIf="dataForm.get('country_id')?.value; else elseCountrySelect">
                    <ng-container *ngIf="!loadingCities; else elseLoadingCities">
                        <ng-container *ngIf="xServices.lengthItems(cities); else elseCities">
                            <mat-option *ngFor="let item of cities | filter: filterCities : 'name'" [value]="item['id']" >{{ item.name }}</mat-option>
                        </ng-container>
                        <ng-template #elseCities>
                            <mat-option [value]="null">No se encontraron ciudades</mat-option>
                        </ng-template>
                    </ng-container>
                    <ng-template #elseLoadingCities>
                        <mat-option [value]="null">Cargando ciudades...</mat-option>
                    </ng-template>
                </ng-container>
                <ng-template #elseCountrySelect>
                    <mat-option [value]="null">Seleccione un País</mat-option>
                </ng-template>
            </mat-select>
        </div>
        <div class="input_group no_space py-2 sm:!px-4">
            <label for="address">Dirección*</label>
            <input id="address" type="text" name="address" formControlName="address" placeholder="Escriba aquí..." />
        </div>
    </ng-container>
</ng-template>

<ng-template #formWilster>
    <ng-container [formGroup]="dataForm">
        <div class="input_group no_space py-2 sm:!px-4">
            <label for="first_name">Nombres*</label>
            <input id="first_name" type="name" name="first_name" formControlName="first_name" placeholder="Escriba aquí..." />
        </div>
        <div class="input_group no_space py-2 sm:!px-4">
            <label for="last_name">Apellidos*</label>
            <input id="last_name" type="name" name="last_name" formControlName="last_name" placeholder="Escriba aquí..." />
        </div>
        <div class="input_group no_space py-2 sm:!px-4">
            <label for="birthdate">Fecha de nacimiento*</label>
            <input id="birthdate" type="date" name="birthdate" formControlName="birthdate" placeholder="Escriba aquí..." />
        </div>

        @if(!type_wilster) {
            <div class="input_group no_space py-2 sm:!px-4">
                <label for="email">Correo electrónico*</label>
                <input id="email" type="email" name="email" formControlName="email" placeholder="Escriba aquí..." />
            </div>
            <div class="input_group no_space py-2 sm:!px-4">
                <label for="phone_number">Celular*</label>
                <input id="phone" type="phone_number" name="phone_number" formControlName="phone_number" placeholder="Escriba aquí..." />
            </div>
            <div class="input_group no_space py-2 sm:!px-4">
                <label for="gender">Sexo*</label>
                <mat-select id="gender" name="gender" formControlName="gender" placeholder="Seleccione una opción" class="custom__mat-select">
                    <div class="filter_option">
                        <input placeholder="Buscar" appSearch (appSearch)="filterGenders = $event" class="!outline-none !ring-0 !border-0">
                    </div>
                    <mat-option *ngFor="let item of genders | filter: filterGenders : 'name'" [value]="item.id" >{{ item.name }}</mat-option>
                </mat-select>
            </div>
        }
    
        <div class="input_group no_space py-2 sm:!px-4">
            <label for="ci_number">Número de C.I.*</label>
            <input id="ci_number" type="number" name="ci_number" formControlName="ci_number" placeholder="Escriba aquí..." />
        </div>
        <div class="input_group no_space py-2 sm:!px-4">
            <label for="ci_expedition_basic">Expedición de C.I.*</label>
            <mat-select id="ci_expedition_basic" name="ci_expedition_basic" formControlName="ci_expedition_basic" placeholder="Seleccione una opción" class="custom__mat-select">
                <div class="filter_option">
                    <input placeholder="Buscar" appSearch (appSearch)="filterExpeditionCI = $event" class="!outline-none !ring-0 !border-0">
                </div>
                <mat-option *ngFor="let item of expeditionData | filter: filterExpeditionCI : 'name'" [value]="item.id" >{{ item.name }}</mat-option>
            </mat-select>
        </div>
        <div class="input_group no_space py-2 sm:!px-4">
            <label for="ci_extension">Complemento - opcional</label>
            <input id="ci_extension" type="text" name="ci_extension" formControlName="ci_extension" placeholder="Escriba aquí..." />
        </div>
    
        @if(!type_wilster) {
            <div class="input_group no_space py-2 sm:!px-4">
                <label for="invoice_name">Razón social</label>
                <input id="invoice_name" type="name" name="invoice_name" formControlName="invoice_name" placeholder="Escriba aquí..." />
            </div>
            <div class="input_group no_space py-2 sm:!px-4">
                <label for="invoice_nit">NIT</label>
                <input id="invoice_nit" type="text" name="invoice_nit" formControlName="invoice_nit" placeholder="Escriba aquí..." />
            </div>
    
            <div class="input_group no_space py-2 sm:!px-4">
                <label for="country_id">País*</label>
                <mat-select id="country_id" name="country_id" formControlName="country_id" placeholder="Seleccione una opción" class="custom__mat-select">
                    <div class="filter_option">
                        <input placeholder="Buscar" appSearch (appSearch)="filterCountries = $event" class="!outline-none !ring-0 !border-0">
                    </div>
                    <ng-container *ngIf="!loadingCountries; else elseLoadingCountries">
                        <ng-container *ngIf="xServices.lengthItems(countries); else elseCountries">
                            <mat-option *ngFor="let item of countries | filter: filterCountries : 'name'" [value]="item['id']" >{{ item.name }} </mat-option>
                        </ng-container>
                        <ng-template #elseCountries>
                            <mat-option [value]="null">No se encontraron paises</mat-option>
                        </ng-template>
                    </ng-container>
                    <ng-template #elseLoadingCountries>
                        <mat-option [value]="null">Cargando paises...</mat-option>
                    </ng-template>
                </mat-select>
            </div>
            <div class="input_group no_space py-2 sm:!px-4">
                <label for="city_id">Ciudad*</label>
                <mat-select id="city_id" name="city_id" formControlName="city_id" [placeholder]="dataForm.get('country_id')?.value ? 'Seleccione una opción' : 'Seleccione un País'" class="custom__mat-select">
                    <div class="filter_option">
                        <input placeholder="Buscar" appSearch (appSearch)="filterCities = $event" class="!outline-none !ring-0 !border-0">
                    </div>
                    <ng-container *ngIf="dataForm.get('country_id')?.value; else elseCountrySelect">
                        <ng-container *ngIf="!loadingCities; else elseLoadingCities">
                            <ng-container *ngIf="xServices.lengthItems(cities); else elseCities">
                                <mat-option *ngFor="let item of cities | filter: filterCities : 'name'" [value]="item['id']" >{{ item.name }}</mat-option>
                            </ng-container>
                            <ng-template #elseCities>
                                <mat-option [value]="null">No se encontraron ciudades</mat-option>
                            </ng-template>
                        </ng-container>
                        <ng-template #elseLoadingCities>
                            <mat-option [value]="null">Cargando ciudades...</mat-option>
                        </ng-template>
                    </ng-container>
                    <ng-template #elseCountrySelect>
                        <mat-option [value]="null">Seleccione un País</mat-option>
                    </ng-template>
                </mat-select>
            </div>
            <div class="input_group no_space py-2 sm:!px-4">
                <label for="address">Dirección*</label>
                <input id="address" type="text" name="address" formControlName="address" placeholder="Escriba aquí..." />
            </div>
    
            <!-- @if(env.type == 'wilstermann') {
                <div class="input_group no_space py-2 sm:!px-4">
                    <label for="shirt_size">Talla*</label>
                    <mat-select id="shirt_size" name="shirt_size" formControlName="shirt_size" placeholder="Seleccione una opción" class="custom__mat-select">
                        <div class="filter_option">
                            <input placeholder="Buscar" appSearch (appSearch)="filterSizes = $event" class="!outline-none !ring-0 !border-0">
                        </div>
                        <ng-container *ngIf="!loadingSizes; else elseLoadingSizes">
                            <ng-container *ngIf="xServices.lengthItems(sizes); else elseSizes">
                                <mat-option *ngFor="let item of sizes | filter: filterSizes : 'label'" [value]="item['value']" >{{ item.label }} </mat-option>
                            </ng-container>
                            <ng-template #elseSizes>
                                <mat-option [value]="null">No se encontraron tallas</mat-option>
                            </ng-template>
                        </ng-container>
                        <ng-template #elseLoadingSizes>
                            <mat-option [value]="null">Cargando tallas...</mat-option>
                        </ng-template>
                    </mat-select>
                </div>
            } -->
        }
    </ng-container>

</ng-template>

<div mat-dialog-title class="!m-0 w-full block before:!hidden">
    <h3 class="m-0 font__title py-4 border-b border-b-gray-300">
        Registro de nuevo perfil
    </h3>
</div>

<div mat-dialog-content class="w-full !p-0 !m-0">
    <div class="container_cy">
        <form class="sm:px-4 w-full" (ngSubmit)="submit()" [formGroup]="dataForm">
            <div class="h-2 w-full"></div>
            <p>Completa la información requerida (*) para crear un perfil </p>
            @if(env.type == 'wilstermann') {
                <div class="input_group no_space py-2 sm:!px-4 mt-4 flex flex-wrap items-center bg-[#ebebeb] !w-fit rounded">
                    <label for="type-wilster">Aviador</label>
                    <div class="switch__field switch__option mx-4">
                        <input type="checkbox" id="type-wilster" name="type-wilster" [(ngModel)]="type_wilster" [ngModelOptions]="{standalone: true}" (change)="typeWilster(type_wilster)">
                        <span></span>
                    </div>
                    <label for="type-wilster">Aviadores desde la cuna</label>
                </div>
            }
            <div class="w-full grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
                @if (env.type == 'wilstermann') {
                    <ng-container [ngTemplateOutlet]="formWilster"></ng-container>
                } @else {
                    <ng-container [ngTemplateOutlet]="formNormal"></ng-container>
                }
            </div>
        </form>
    </div>
</div>

<div mat-dialog-actions class="!m-0 w-full block">
    <div class="w-full flex flex-wrap justify-between actions_modal">
        <div class="block"></div>
        <div class="flex flex-wrap justify-between gap-2">
            <button mat-stroked-button type="button" (click)="dismiss()" class="cy__btn">Cancelar</button>

            <div class="flex flex-wrap gap-2">
                <button-cy [disabled]="dataForm.invalid" (onPress)="submit()" addClass="btn__primary">Registrar perfil</button-cy>
            </div>
        </div>
    </div>
</div>
